<script setup>
import { ref } from "vue";
import Swal from "sweetalert2";

let email = ref("");
let emailError = ref(false);
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

let handleSubmit = () => {
  if (!email.value || !emailRegex.test(email.value)) {
    emailError.value = true;
  } else {
    console.log(emailRegex.test(email.value));
    Swal.fire({
      icon: "success",
      title: "Thank You",
      text: `${email.value} subscribed to our newsletter!`,
    });
  }
};
</script>

<template>
  <!-- Newsletter Section Start -->
  <div id="subscribe" class="bg-theme-primary mt-10">
    <!-- <div class="container w-full lg:w-2/5 mx-auto px-5"> -->
    <section class="py-16 mx-2">
      <h4
        class="text-sm tracking-widest uppercase text-center text-white font-theme-heading"
      >
        35,000+ Already Joined
      </h4>
      <h2
        class="text-3xl md:text-4xl font-medium text-center text-white mt-9 mb-10 font-theme-heading"
      >
            Place your order and stay updated
      </h2>

      <!-- <v-stepper color="success">
        <v-stepper-header>
          <v-stepper-item
            title="Place an Order"
            value="1"
            color="primary"
            complete
          ></v-stepper-item>

          <v-divider></v-divider>

          <v-stepper-item
            title="Select a Writer"
            value="2"
            color="primary"
            complete
          ></v-stepper-item>

          <v-divider></v-divider>

          <v-stepper-item
            title="Track your projects"
            value="3"
            color="primary"
            complete
          ></v-stepper-item>

          <v-divider></v-divider>

          <v-stepper-item
            title="Receive your Paper"
            value="4"
            color="primary"
            complete
          ></v-stepper-item>
        </v-stepper-header>
      </v-stepper> -->
    </section>
  </div>
  <!-- </div> -->
  <!-- Newsletter Section End -->
</template>
