<template>
  <div>
    <div class="d-flex align-center justify-center" style="height: 90vh">
      <v-card elevation="0" width="400" class="mx-auto mt-n9">
        <v-card-title class="text-center mb-5">
          Forgot your password?
          <div class="mt-3 text-caption">
            <span class="text-grey">
              Enter your email to recieve verification code
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent class="ma-3">
            <v-text-field
              v-model="editedItem.email"
              :readonly="loading"
              :rules="[required]"
              class="mb-2 mt-n1"
              clearable
              variant="outlined"
              label="Email"
            ></v-text-field>
            <v-btn
              :loading="loading"
              block
              color="primary"
              size="large"
              type="submit"
              elevation="0"
              class="mt-3"
              @click="save"
            >
              <span class="text-white"> Request verification code</span>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      email: "",
      loading: false,
      editedItem: {
        email: "",
      },
    };
  },

  methods: {
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    async save() {
      this.loading = true;
      AuthService.forgotPasswordOtp(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.email = response.data.data;
            localStorage.setItem("password_reset_email", this.editedItem.email);
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/verify");
            this.loading = false;
          } else {
            console.log(response, "res");
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>