import { createApp } from 'vue'
import "./assets/style.css";
import App from './App.vue'
import VueSmoothScroll from "vue3-smooth-scroll";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'vuetify/styles';

import Button from "./components/Button.vue";
import LinkButton from "./components/LinkButton.vue";
import PostOrder from "./components/PostOrder.vue";
import AboutContact from "./components/AboutContact.vue";
import Login from "@/components/auth/LoginPage.vue"

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueSmoothScroll)
  .use(PostOrder)
  .use(AboutContact)
  .use(Login)
  .component("Button", Button)
  .component("LinkButton", LinkButton)
  .mount('#app')
