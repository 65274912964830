// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class AccessLogsService {

    getAcademicLevels() {
        return axios.get(API_URL + 'public-academic-levels', { headers: authHeader() })
    }
    getCitations() {
        return axios.get(API_URL + 'public-citations', { headers: authHeader() })
    }
    showCpp(resource) {
        return axios.post(API_URL + 'public-cpps', resource, { headers: authHeader() })
    }
    postOrder(resource) {
        return axios.post(API_URL + 'public-orders', resource, { headers: authHeader() })
    }
    getProjectTypes() {
        return axios.get(API_URL + 'public-project-types', { headers: authHeader() })
    }
    getSubjects() {
        return axios.get(API_URL + 'public-subjects', { headers: authHeader() })
    }
}

export default new AccessLogsService()