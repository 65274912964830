import axios from 'axios'
import authHeader from './auth-header'
const API_URL = process.env.VUE_APP_API_URL;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login', {
                email: user.email,
                password: user.password
            }).then(response => {
                return response
            });
    }
    oathLogin(provider) {
        return axios.get(API_URL + 'login/' + provider, { headers: authHeader() })
    }

    logout() {
        localStorage.removeItem('user')
    }

    async checkAuth() {
        return axios.get(API_URL + 'users', { headers: authHeader() }).then(
            (res) => {
                return res.status
            },
            (error) => {
                return error.response.status
            });
    }

    register(user) {
        return axios
            .post(API_URL + 'auth/register', {
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                password: user.password,
                rct_id: user.rct_id,
                number: user.number,
                description: user.description,
                devicetoken: user.devicetoken,
            })
    }


    forgotPasswordOtp(resource) {
        return axios.post(API_URL + 'generate-otp', resource, { headers: authHeader() })
    }

    verifyOtp(resource) {
        return axios.post(API_URL + 'verify-otp', resource, { headers: authHeader() })
    }

    resetPassword(resource) {
        return axios.post(API_URL + 'reset-password', resource, { headers: authHeader() })
    }

    selfRegister(resource) {
        return axios.post(API_URL + 'register', resource, { headers: authHeader() })
    }
}

export default new AuthService()