// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class UserService {

    index() {
        return axios.get(API_URL + 'users', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'users/' + id, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(API_URL + 'users', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(API_URL + 'users/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(API_URL + 'users/' + resource.id, { headers: authHeader() })
    }
}

export default new UserService()