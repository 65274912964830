<template>
  <v-app>
    <v-main>
      <AlertComponent />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AlertComponent from "@/components/alertComponent.vue";
export default {
  name: "App",
  components: {
    AlertComponent,
  },
  data: () => ({
    //
  }),
};
</script>
