const faqs = [
    {
        id: 1,
        title: "Who writes this essays",
        description: "We hire current and former academics, graduate students who teach their own classes, with advanced graduate degrees, ninjas, and warlock Vatican assassins. All of our writers' credentials are objectively verified prior to their acceptance.",
    },
    {
        id: 2,
        title: "Can you write in any citations or use my required resources?",
        description: "We can write in any style that you or your professor could possibly contrive. useriters' super wizard has provided us with software via which we input data and magically conjure out references in any formatting style on the face of the earth. As for required sources, we most certainly can use and abuse them for you. It should be noted that, in some cases when a book is unavailable from the academic libraries that we are affiliated with, and an integral part of your assignment requires a sustained examination of it, we will need to purchase the book with your funds. In this case, you will be advised and charged the going price on Amazon plus the required shipping if your order is time-sensitive.",
    },
    {
        id: 3,
        title: "Is my confidentiality and privacy ensured?",
        description: "The only thing that we care more about than your privacy is our own. In that vein, we (as in, the writers) want to know as little about you as possible. “We” in this context refers to the writers and not the site. All of your credit card information is handled by a third-party. All that the writer knows about you is the name (noms de guerre permitted) that you provide us through your username and the assignment that you're commissioning us to complete. The writers don't know any more than that and sure as hell don't want to. So, yeah, your confidentiality and privacy are guaranteed.",
    },
    {
        id: 4,
        title: "Is it illegal to buy an essay?",
        description: "Absolutely not - we offer custom research tailored to your needs. We might be mercenaries but sure as hell are not going to pop a cap in anybody's ass. We're just transferring copyright over research that we've done, in exchange for our fee, to you. Feel free to do whatever you want with the essay - you own it. Karl Marx would flip around in his grave but that's the beauty of capitalism baby!",
    },
];

export default faqs;
