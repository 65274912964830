const downloads = [
    {
        id: 1,
        icon: "images/logo-chrome.svg",
        title: "Add to Chrome",
        subTitle: "Minimum version 62",
        link: "#",
    },
    {
        id: 2,
        icon: "images/logo-firefox.svg",
        title: "Add to Firefox",
        subTitle: "Minimum version 55",
        link: "#",
    },
    {
        id: 3,
        icon: "images/logo-opera.svg",
        title: "Add to Opera",
        subTitle: "Minimum version 46",
        link: "#",
    },
];

export default downloads;
