const initialState = {
    color: '',
    message: '',
    icon:'',
    show: false
}

export const alert = {
    namespaced: true,
    state: initialState,
    actions: {
        success({ commit }, message) {
            commit('success', message)
        },
        error({ commit }, message) {
            commit('error', message)
        },
        info({ commit }, message) {
            commit('info', message)
        },
        clear({ commit }) {
            commit('clear')
        }
    },

    mutations: {
        success(state, message) {
            state.color = 'success';
            state.message = message;
            state.icon = 'mdi-check-circle';
            state.show = true;
        },
        error(state, message) {
            state.color = 'error';
            state.message = message;
            state.icon = 'mdi-close-circle';
            state.show = true;
        },
        info(state, message) {
            state.color = 'info';
            state.message = message;
            state.icon = 'mdi-information-slab-circle';
            state.show = true;
        },
        clear(state) {
            state.color = '';
            state.message = '';
            state.icon = '';
            state.show = false;
        }
    },

    getters: {
        getColor( state ){
            return state.color;
        },

        getMessage( state ){
            return state.message;
        },

        getShow( state ){
            return state.show;
        },

        getIcon( state ){
            return state.icon;
        }
    }

}