<template>
  <div id="about" class="mx-7">
    <div class="w-4/5 md:w-3/5 mx-auto">
      <h2
        class="text-3xl md:text-4xl font-theme-heading font-medium text-center"
      >
        About Us
      </h2>
      <p
        class="text-theme-grayish-blue text-center text-lg font-theme-content mt-7"
      >
        Learn more about who we are and what drives us and Reach out to us if
        you have questions.
      </p>
    </div>

    <v-row class="mx-2 mt-6">
      <v-col cols="12" md="6">
        <v-card elevation="0" variant="tonal" class="pa-8 mt-3 text-justify">
          <!-- here -->
          <div class="text-uppercase text-primary mt-1 text-subtitle-2">
            About Us
          </div>
          <p class="text-body-2">
            Welcome to US Writers, your go-to destination for top-notch essay
            writing services. We’re a dedicated team of professional writers and
            editors committed to helping students and professionals achieve
            their academic and career goals through exceptional writing.
          </p>
          <div class="text-uppercase text-primary mt-1 text-subtitle-2">
            Our Mission
          </div>
          <p class="mt-1 text-body-2">
            At US Writers, our mission is to provide high-quality, original, and
            timely essay writing services that exceed your expectations. We
            understand that every assignment is unique, which is why we tailor
            our approach to meet your specific needs. Our goal is to alleviate
            the stress of academic and professional writing by delivering
            well-researched, expertly crafted essays that help you shine.
          </p>
          <div class="text-uppercase mt-1 text-primary text-subtitle-2">
            Who We Are
          </div>
          <p class="mt-1 text-body-2">
            We’re a diverse group of experienced writers, researchers, and
            editors based in the United States. Our team includes former
            professors, published authors, and industry professionals who bring
            a wealth of knowledge and a passion for writing to every project. We
            pride ourselves on our ability to tackle a wide range of topics and
            formats, ensuring that your essay is not only well-written but also
            insightful and engaging.
          </p>
          <!-- end of here -->
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card elevation="0" class="pa-3">
          <v-card-title class="text-center mb-2 mt-n2">
            <div class="text-uppercase text-subtitle-2">Contact Us</div>
          </v-card-title>
          <v-form ref="form" v-model="isValid" @submit.prevent="save">
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="editedItem.name"
                  label="Name"
                  :rules="rules.name"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  v-model="editedItem.email"
                  label="Email"
                  :rules="rules.slug"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="editedItem.subject"
                  label="Subject"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="editedItem.description"
                  label="Message"
                  variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="elevated"
              @click="profileSave"
              class="mt-n1 mr-n2"
              :loading="loading"
            >
              Send Message
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isValid: false,
    rules: {
      name: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a name.";
        },
      ],
      slug: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a email.";
        },
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Name", value: "title", key: "title" },
      { title: "Slug", value: "slug", key: "slug" },
      { title: "Category", value: "category.name", key: "category.name" },
      { title: "Description", value: "description", key: "description" },
      { title: "Actions", key: "actions", sortable: false },
    ],

    editedIndex: -1,
    subcategories: [],
    categories: [],
    editedItem: {
      name: "",
      title: "",
      slug: "",
      description: "",
    },
    defaultItem: {
      name: "",
      title: "",
      slug: "",
      description: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Listing" : "Edit Listing";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    //
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    getSubcategories() {
      return SubategoriesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.subcategories = response.data.data;
            this.loading = false;
          } else {
            this.subcategories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.subcategories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCategories() {
      return CategoriesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    editItem(item) {
      this.editedIndex = this.subcategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.subcategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      SubategoriesService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.subcategories.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.name = this.editedItem.title;
      if (this.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.subcategories[this.editedIndex], this.editedItem);
          SubategoriesService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                this.loading = false;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          SubategoriesService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.subcategories.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>

